import React from 'react';

import './app-header.css';

const AppHeader = ({toDo, doneIt}) => {

    const txt = (toDo > 0) ? `${toDo} more to do, ${doneIt} done` : 'No tasks!'

    return (
        <div className="app-header d-flex">
            <h1>Todo List</h1>
            <h2>{txt}</h2>
        </div>
    )
}

export default AppHeader;