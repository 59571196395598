import React from 'react';

import './todo-list.css';

import TodoListItem from '../todo-list-item/';

const TodoList = ({ todos, onToggleImportant, onToggleDone, onDeleteItem }) => {

    const items = todos.map((item) => {
        const {id, label, important, done} = item;

        return (
            <li 
                key={id} 
                className="list-group-item">
                <TodoListItem 
                    itemName={label}
                    important={important}
                    done={done}
                    onToggleDone={() => onToggleDone(id)}
                    onToggleImportant={() => onToggleImportant(id)}
                    onDeleteItem={() => onDeleteItem(id)}/>
            </li>
        );
    });

    return(
        <ul className="list-group todo-list">
            {items}
        </ul>
    );
}

export default TodoList;