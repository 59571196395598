import React, {Component} from 'react';

import './search-panel.css';

export default class SearchPanel extends Component {
    constructor() {
        super();

        this.state = {
            term: ''
        }
    }

    onSearchChange = (event) => {
		const term = event.target.value;

		this.setState({term});
		this.props.onSearchChange(term);
	}


    render() {
        return(
            <input 
                placeholder="type to search" 
                className="search-input form-control" 
                type="text"
                value={this.state.term}
                onChange={this.onSearchChange}/>
        );
    }
}