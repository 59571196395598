import React, {Component} from 'react';

import AppHeader from '../app-header/';
import SearchPanel from '../search-panel/';
import ItemStatusFilter from '../item-status-filter/';
import TodoList from '../todo-list/';
import ItemAddForm from '../item-add-form';
import * as Storage from '../../storage';

import './app.css';

export default class App extends Component {
    constructor() {
        super();

        this.state = {
            todoData: Storage.getFromStorage('todoData') || [
                this.createElem('Create a new task'),
                this.createElem('Feed the dog'),
                this.createElem('Go grocery shopping')
            ],
            term: '',
            filter: 'all'
        };
    }

    maxId = Storage.getFromStorage('maxId') || 100;

    createElem(label) {
        return {
            label,
            id: this.maxId++,
            important: false,
            done: false
        }
    }

    changePropsState(id, arr, prop) {
        const idx = arr.findIndex((el) => el.id === id);
        const oldItem = arr[idx];
        const newItem = {...oldItem, [prop]: !oldItem[prop]}

        const newArr = [...arr.slice(0, idx), newItem, ...arr.slice(idx + 1)];
        Storage.addToStorage('todoData', newArr);
        return newArr;
    }

    onChangeImportant = (id) => {
        this.setState(({todoData}) => {
            return {
                todoData: this.changePropsState(id, todoData, 'important')
            }
        });
    }

    onChangeDone = (id) => {
        this.setState(({todoData}) => {
            return {
                todoData: this.changePropsState(id, todoData, 'done')
            }
        });
    }

    onDelete = (id) => {
        const idx = this.state.todoData.findIndex((el) => {
            return el.id === id;
        });

        const newArr = [...this.state.todoData];
        newArr.splice(idx, 1);

        Storage.addToStorage('todoData', newArr);

        this.setState({
            todoData: newArr
        });
    }

    addNewItem = (name) => {
       const arr = [...this.state.todoData];
    
        arr.push(this.createElem(name));

        Storage.addToStorage('todoData', arr);
        Storage.addToStorage('maxId', this.maxId);

        this.setState({
            todoData: arr
        });
    }

    search(term, items) {
        if (term.length === 0) {
            return items;
        }

        return items.filter((el) => {
            return el.label.toLowerCase().indexOf(term.toLowerCase()) > -1;
        });
    }

    onSearchChange = (term) => {
        this.setState({term});
    }

    onFilterChange = (filter) => {
        this.setState({ filter })
    }

    filter(items, filter) {
        switch (filter) {
            case 'all':
                return items;
            case 'active':
                return items.filter(item => !item.done);
            case 'done':
                return items.filter(item => item.done);
            default:
                return items;
        }
    }

    render() {
        const {todoData, term, filter} = this.state;
        const countItem = todoData.length;
        const doneItemsArr = todoData.filter((item) => {
            return item.done === true;
        });
        const doneItems = doneItemsArr.length;
        const visibleItems = this.filter(this.search(term, todoData), filter);

        return(
            <div className="todo-app">
                <AppHeader toDo={countItem} doneIt={doneItems}/>

                <div className="top-panel d-flex">
                    <SearchPanel onSearchChange={this.onSearchChange}/>
                    <ItemStatusFilter filter={filter} onFilterChange={this.onFilterChange}/>
                </div>
                <TodoList 
                    todos={visibleItems}
                    onToggleImportant={this.onChangeImportant}
                    onToggleDone={this.onChangeDone}
                    onDeleteItem={this.onDelete}/>
                <ItemAddForm addNewItem={this.addNewItem}/>
            </div>
        );
    }
}