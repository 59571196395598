import React, {Component} from 'react';

import './item-status-filter.css';

const _buttonsData = [
    {name: 'all', label: 'All'},
    {name: 'active', label: 'Active'},
    {name: 'done', label: 'Done'}
];

export default class ItemStatusFilter extends Component {
    render() {

        const {filter, onFilterChange} = this.props;

        const buttons = _buttonsData.map(({name, label}) => {
            const isActive = filter === name;
            const classesName = isActive ? 'btn-info' : 'btn-outline-secondary';

            return (
                <button
                    key={name} 
                    className={`btn ${classesName}`} 
                    onClick={() => onFilterChange(name)} 
                    type="button">{label}</button>
            )
        })

        return(
            <div className="btn-group">
                {buttons}
            </div>
        );
    }
}