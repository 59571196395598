import React from 'react';

import './todo-list-item.css';

const TodoListItem = ({itemName, onToggleImportant, onToggleDone, important, done, onDeleteItem}) => {

    let classes = 'todo-list-item';

    if (important) {
        classes += ' important';
    }

    if (done) {
        classes += ' done';
    }

    return (
        <span className={classes}>
            <span 
                className="todo-list-item-label"
                onClick={onToggleDone}>
                {itemName}
            </span>
            <button 
                type="button" 
                className="btn btn-outline-success btn-sm float-right"
                onClick={onToggleImportant}>
                <i className="fa fa-exclamation"></i>
            </button>
            <button 
                type="button" 
                className="btn btn-outline-danger btn-sm float-right"
                onClick={onDeleteItem}>
                <i className="fa fa-trash"></i>
            </button>
        </span>
    )
}

export default TodoListItem;